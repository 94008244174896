import React, { useLayoutEffect } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => {
  useLayoutEffect(() => {
    const unactiveCategory = new RegExp('/\\d+/?$', 'gm');

    if (unactiveCategory.test(window.location.href)) {
      window.location.href = window.location.href.replace(unactiveCategory, '')
    }
  }, []);
  
  return (
    <Layout>
      <SEO title="404: Nie znaleziono strony" />
      <h1>Nie znaleziono strony</h1>
      <p>Taka strona nie istnieje :(</p>
      <p><Link to={'/'}>Powrót na stronę główną</Link> </p>
    </Layout>
  )
}

export default NotFoundPage
